<ag-grid-angular
  #agGrid
  *ngIf="rowData$"
  class="ag-theme-material mt-2"
  [pagination]="true"
  domLayout="autoHeight"
  [animateRows]="true"
  [paginationPageSize]="10"
  [rowData]="rowData$ | async"
  [columnDefs]="columnDefs"
  [enableCellChangeFlash]="true"
  (firstDataRendered)="onFirstDataRendered($event)"
  (gridSizeChanged)="onGridSizeChanged($event)"
  [components]="components"
>
</ag-grid-angular>
