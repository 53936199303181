import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CcpcTournament } from '@codecraft-works/data-models';
import { AgGridAngular } from 'ag-grid-angular';
import {
  FirstDataRenderedEvent,
  GridSizeChangedEvent,
} from 'ag-grid-community/dist/lib/events';
import firebase from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationDirective } from '../pagination.directive';
import { PaginationService } from '../pagination.service';
import { CoachEditButtonCellComponent } from './coach-edit-button-cell/coach-edit-button-cell.component';
import { CompetitionEditButtonCellComponent } from './competition-edit-button-cell/competition-edit-button-cell.component';
import { EmailLinkCellComponent } from './email-link-cell/email-link-cell.component';
import { GameProjectTitleLinkCellComponent } from './game-project-title-link-cell/game-project-title-link-cell.component';
import { K12StudentEditButtonCellComponent } from './k12student-edit-button-cell/k12student-edit-button-cell.component';
import { ProgramCopyButtonCellComponent } from './program-copy-button-cell/program-copy-button-cell.component';
import { ProgramEditButtonCellComponent } from './program-edit-button-cell/program-edit-button-cell.component';
import { ProgramTitleLinkCellComponent } from './program-title-link-cell/program-title-link-cell.component';
import { SeatCountCellComponent } from './seat-count-cell/seat-count-cell.component';
import { SponsorEditButtonCellComponent } from './sponsor-edit-button-cell/sponsor-edit-button-cell.component';
import { SubmissionProjectCellComponent } from './submission-project-cell/submission-project-cell.component';
import { TournamentEditButtonCellComponent } from './tournament-edit-button-cell/tournament-edit-button-cell.component';
import { UserEditButtonCellComponent } from './user-edit-button-cell/user-edit-button-cell.component';
import { UserRoleCellComponent } from './user-role-cell/user-role-cell.component';
import { UsernameLinkCellComponent } from './username-link-cell/username-link-cell.component';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css'],
})
export class TableViewComponent extends PaginationDirective implements OnInit {
  @Input() isAdminView = false;

  @ViewChild('agGrid') agGrid: AgGridAngular;
  @ViewChild('agGrid', { read: ElementRef }) agGridElementRef: ElementRef;

  rowData$: Observable<any>;
  observableData$: Observable<any>;
  columnDefs = [];
  gridWidth: number;

  components = {
    programEditButtonCellComponent: ProgramEditButtonCellComponent,
    programCopyButtonCellComponent: ProgramCopyButtonCellComponent,
    competitionEditButtonCellComponent: CompetitionEditButtonCellComponent,
    seatCountCellComponent: SeatCountCellComponent,
    programTitleLinkCellComponent: ProgramTitleLinkCellComponent,
    gameProjectTitleLinkCellComponent: GameProjectTitleLinkCellComponent,
    tournamentEditButtonCellComponent: TournamentEditButtonCellComponent,
    submissionProjectCellComponent: SubmissionProjectCellComponent,
    emailLinkCellComponent: EmailLinkCellComponent,
    usernameLinkCellComponent: UsernameLinkCellComponent,
    userRoleCellComponent: UserRoleCellComponent,
    userEditButtonCellComponent: UserEditButtonCellComponent,
    coachEditButtonCellComponent: CoachEditButtonCellComponent,
    sponsorEditButtonCellComponent: SponsorEditButtonCellComponent,
    k12StudentEditButtonCellComponent: K12StudentEditButtonCellComponent,
  };

  gameProjectFields = {
    name: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 300,
      wrapText: true,
      autoHeight: true,
      cellRenderer: 'gameProjectTitleLinkCellComponent',
    },
    modified: {
      field: 'modified',
      resizable: true,
      sortable: true,
      filter: 'agDateColumnFilter',
      minWidth: 300,
      valueFormatter: this.fullTimeDateFormatter,
    },
    type: {
      field: 'type',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 150,
    },
    language: {
      field: 'language',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 150,
    },
  };

  programFields = {
    name: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 300,
      cellRenderer: 'programTitleLinkCellComponent',
      wrapText: true,
      autoHeight: true,
    },
    truncatedName: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      cellRenderer: 'programTitleLinkCellComponent',
      wrapText: false,
      autoHeight: true,
    },
    level: {
      field: 'level',
      resizable: true,
      sortable: true,
      filter: 'agNumberColumnFilter',
      minWidth: 50,
    },
    seats: {
      field: 'seats',
      resizable: true,
      sortable: true,
      cellRenderer: 'seatCountCellComponent',
      minWidth: 25,
    },
    ageGroup: {
      field: 'ageGroup',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 40,
      comparator: (valueA, valueB) => {
        let age1;
        let age2;
        valueA ? (age1 = Number(valueA.split('-')[0])) : (age1 = 0);
        valueB ? (age2 = Number(valueB.split('-')[0])) : (age2 = 0);
        if (age1 === age2) {
          return 0;
        }
        return age1 > age2 ? 1 : -1;
      },
    },
    startDate: {
      field: 'startDate',
      resizable: true,
      sortable: true,
      filter: 'agDateColumnFilter',
      minWidth: 150,
      valueFormatter: this.dateValueFormatter,
    },
    startTime: {
      field: 'startDate',
      headerName: 'Start Time',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 50,
      wrapText: true,
      autoHeight: true,
      valueFormatter: this.timeValueFormatter,
    },
    days: {
      field: 'days',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 200,
      wrapText: false,
      autoHeight: true,
    },
    price: {
      field: 'stripe.price',
      headerName: 'Price',
      resizable: true,
      wrapText: true,
      autoHeight: true,
      valueFormatter: this.priceValueFormatter,
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    archive: {
      field: 'archive',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
    },
    public: {
      field: 'public',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
    },
    edit: {
      headerName: 'Edit',
      cellRenderer: 'programEditButtonCellComponent',
      minWidth: 100,
    },
    copy: {
      headerName: 'Copy',
      cellRenderer: 'programCopyButtonCellComponent',
      minWidth: 100,
    },
  };

  competitionFields = {
    name: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
    },
    truncatedName: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: false,
      autoHeight: true,
    },
    competitionDate: {
      field: 'competitionDate',
      resizable: true,
      sortable: true,
      filter: 'agDateColumnFilter',
      minWidth: 100,
      valueFormatter: this.dateValueFormatter,
    },
    sponsors: {
      field: 'sponsors',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 200,
    },
    edit: {
      headerName: 'Edit',
      cellRenderer: 'competitionEditButtonCellComponent',
      width: 150,
    },
  };

  tournamentFields = {
    name: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
    },
    truncatedName: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: false,
      autoHeight: true,
    },
    type: {
      headerName: 'Type',
      field: 'type',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
    },
    coaches: {
      headerName: 'Coaches',
      field: 'entities',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 200,
    },
    edit: {
      headerName: 'Edit',
      cellRenderer: 'tournamentEditButtonCellComponent',
      minWidth: 100,
    },
  };

  submissionFields = {
    name: {
      field: 'studentName',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
    },
    truncatedName: {
      field: 'studentName',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: false,
      autoHeight: true,
    },
    submittedTime: {
      field: 'submitted',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
      valueFormatter: this.fullTimeDateFormatter,
    },
    coach: {
      headerName: 'Coach',
      field: 'coach',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
    },
    project: {
      headerName: 'Project',
      field: 'project',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      cellRenderer: 'submissionProjectCellComponent',
      minWidth: 200,
    },
  };

  coachFields = {
    name: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
    },
    truncatedName: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: false,
      autoHeight: true,
    },
    email: {
      field: 'email',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
      cellRenderer: 'emailLinkCellComponent',
    },
    organization: {
      field: 'organization',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
    },
    edit: {
      field: 'edit',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
      cellRenderer: 'coachEditButtonCellComponent',
    },
  };

  sponsorFields = {
    name: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
    },
    truncatedName: {
      field: 'name',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: false,
      autoHeight: true,
    },
    email: {
      field: 'email',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
      cellRenderer: 'emailLinkCellComponent',
    },
    organization: {
      field: 'organization',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
    },
    edit: {
      field: 'edit',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
      cellRenderer: 'sponsorEditButtonCellComponent',
    },
  };

  userFields = {
    displayName: {
      field: 'displayName',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
    },
    email: {
      field: 'email',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
      cellRenderer: 'emailLinkCellComponent',
    },
    userName: {
      field: 'userName',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
      cellRenderer: 'usernameLinkCellComponent',
    },
    roles: {
      field: 'roles',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
      cellRenderer: 'userRoleCellComponent',
    },
    creationDate: {
      field: 'created',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
      valueFormatter: this.fullTimeDateFormatter,
    },
    details: {
      headerName: 'Details',
      cellRenderer: 'userEditButtonCellComponent',
      width: 150,
    },
  };

  k12StudentFields = {
    displayName: {
      field: 'user.displayName',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
    },

    userName: {
      field: 'userName',
      resizable: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      minWidth: 100,
    },

    creationDate: {
      field: 'created',
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 225,
      wrapText: true,
      autoHeight: true,
      valueFormatter: this.fullTimeDateFormatter,
    },

    edit: {
      headerName: 'Edit',
      cellRenderer: 'k12StudentEditButtonCellComponent',
      width: 150,
    },
  };

  allColumnDefs = {
    programColumnDefs: {
      mobile: [this.programFields.truncatedName, this.programFields.price],

      medium: [
        this.programFields.name,
        this.programFields.level,
        this.programFields.price,
      ],

      large: [
        this.programFields.name,
        this.programFields.level,
        this.programFields.startDate,
        this.programFields.price,
      ],

      xl: [
        this.programFields.name,
        this.programFields.level,
        this.programFields.ageGroup,
        this.programFields.startDate,
        this.programFields.startTime,
        this.programFields.days,
        this.programFields.price,
      ],
    },
    adminProgramColumnDefs: {
      mobile: [this.programFields.truncatedName, this.programFields.edit],

      medium: [
        this.programFields.truncatedName,
        this.programFields.startDate,
        this.programFields.edit,
      ],

      large: [
        this.programFields.truncatedName,
        this.programFields.seats,
        this.programFields.startDate,
        this.programFields.startTime,
        this.programFields.days,
        this.programFields.edit,
        this.programFields.copy,
      ],

      xl: [
        this.programFields.truncatedName,
        this.programFields.seats,
        this.programFields.startDate,
        this.programFields.startTime,
        this.programFields.days,
        this.programFields.level,
        this.programFields.ageGroup,
        this.programFields.public,
        this.programFields.archive,
        this.programFields.edit,
        this.programFields.copy,
      ],
    },
    gameProjectColumnDefs: {
      mobile: [this.gameProjectFields.name, this.gameProjectFields.type],
      medium: [
        this.gameProjectFields.name,
        this.gameProjectFields.type,
        this.gameProjectFields.language,
      ],
      large: [
        this.gameProjectFields.name,
        this.gameProjectFields.type,
        this.gameProjectFields.language,
        this.gameProjectFields.modified,
      ],
      xl: [
        this.gameProjectFields.name,
        this.gameProjectFields.type,
        this.gameProjectFields.language,
        this.gameProjectFields.modified,
      ],
    },
    competitionColumnDefs: {
      mobile: [this.competitionFields.truncatedName],

      medium: [
        this.competitionFields.name,
        this.competitionFields.sponsors,
        this.competitionFields.competitionDate,
        this.competitionFields.edit,
      ],

      large: [
        this.competitionFields.name,
        this.competitionFields.sponsors,
        this.competitionFields.competitionDate,
        this.competitionFields.edit,
      ],

      xl: [
        this.competitionFields.name,
        this.competitionFields.sponsors,
        this.competitionFields.competitionDate,
        this.competitionFields.edit,
      ],
    },
    tournamentColumnDefs: {
      mobile: [this.tournamentFields.truncatedName],

      medium: [
        this.tournamentFields.name,
        this.tournamentFields.type,
        this.tournamentFields.coaches,
        this.tournamentFields.edit,
      ],

      large: [
        this.tournamentFields.name,
        this.tournamentFields.type,
        this.tournamentFields.coaches,
        this.tournamentFields.edit,
      ],

      xl: [
        this.tournamentFields.name,
        this.tournamentFields.type,
        this.tournamentFields.coaches,
        this.tournamentFields.edit,
      ],
    },
    submissionColumnDefs: {
      mobile: [this.submissionFields.truncatedName],

      medium: [
        this.submissionFields.name,
        this.submissionFields.coach,
        this.submissionFields.project,
      ],

      large: [
        this.submissionFields.name,
        this.submissionFields.coach,
        this.submissionFields.project,
        this.submissionFields.submittedTime,
      ],

      xl: [
        this.submissionFields.name,
        this.submissionFields.coach,
        this.submissionFields.project,
        this.submissionFields.submittedTime,
      ],
    },
    coachColumnDefs: {
      mobile: [this.coachFields.truncatedName],

      medium: [
        this.coachFields.name,
        this.coachFields.organization,
        this.coachFields.edit,
      ],

      large: [
        this.coachFields.name,
        this.coachFields.organization,
        this.coachFields.email,
        this.coachFields.edit,
      ],

      xl: [
        this.coachFields.name,
        this.coachFields.organization,
        this.coachFields.email,
        this.coachFields.edit,
      ],
    },
    sponsorColumnDefs: {
      mobile: [this.sponsorFields.truncatedName],

      medium: [
        this.sponsorFields.name,
        this.sponsorFields.organization,
        this.sponsorFields.edit,
      ],

      large: [
        this.sponsorFields.name,
        this.sponsorFields.organization,
        this.sponsorFields.email,
        this.sponsorFields.edit,
      ],

      xl: [
        this.sponsorFields.name,
        this.sponsorFields.organization,
        this.sponsorFields.email,
        this.sponsorFields.edit,
      ],
    },
    userColumnDefs: {
      mobile: [this.userFields.displayName],

      medium: [
        this.userFields.displayName,
        this.userFields.email,
        this.userFields.creationDate,
        this.userFields.details,
      ],

      large: [
        this.userFields.displayName,
        this.userFields.email,
        this.userFields.userName,
        this.userFields.roles,
        this.userFields.creationDate,
        this.userFields.details,
      ],

      xl: [
        this.userFields.displayName,
        this.userFields.email,
        this.userFields.userName,
        this.userFields.roles,
        this.userFields.creationDate,
        this.userFields.details,
      ],
    },
    k12StudentColumnDefs: {
      mobile: [
        this.k12StudentFields.userName,
        this.k12StudentFields.displayName,
      ],

      medium: [
        this.k12StudentFields.userName,
        this.k12StudentFields.creationDate,
        this.k12StudentFields.displayName,
        this.k12StudentFields.edit,
      ],

      large: [
        this.k12StudentFields.userName,
        this.k12StudentFields.creationDate,
        this.k12StudentFields.displayName,
        this.k12StudentFields.edit,
      ],

      xl: [
        this.k12StudentFields.userName,
        this.k12StudentFields.creationDate,
        this.k12StudentFields.displayName,
        this.k12StudentFields.edit,
      ],
    },
  };

  constructor(public page: PaginationService) {
    super(page);
  }

  ngOnInit(): void {
    if (this.targetObservableArray$) {
      this.observableData$ = this.targetObservableArray$;
    } else if (this.targetArray) {
      this.observableData$ = of(this.targetArray);
    } else {
      super.init();
      this.observableData$ = this.page.data;
    }

    switch (this.path) {
      case 'programs':
        this.setColumnDefs(
          this.isAdminView
            ? this.allColumnDefs.adminProgramColumnDefs
            : this.allColumnDefs.programColumnDefs
        );
        this.rowData$ = this.observableData$.pipe(
          map((programs) => {
            return programs.map((program) => {
              // to hold program object with extra formatted values for date and time
              const formattedProgram: any = program as any;

              if (program.level) {
                formattedProgram.level = Number(program.level);
              }

              if (program.daysOfTheWeek) {
                const daysString = [];

                if (program.daysOfTheWeek.monday) {
                  daysString.push('Mon');
                }
                if (program.daysOfTheWeek.tuesday) {
                  daysString.push('Tue');
                }
                if (program.daysOfTheWeek.wednesday) {
                  daysString.push('Wed');
                }
                if (program.daysOfTheWeek.thursday) {
                  daysString.push('Thu');
                }
                if (program.daysOfTheWeek.friday) {
                  daysString.push('Fri');
                }
                if (program.daysOfTheWeek.saturday) {
                  daysString.push('Sat');
                }
                if (program.daysOfTheWeek.sunday) {
                  daysString.push('Sun');
                }
                formattedProgram.days = daysString.join(' ');
              }

              if (program.timeAndDate && program.timeAndDate.startDateTime) {
                formattedProgram.startDate =
                  program.timeAndDate.startDateTime.toDate();
              }

              return formattedProgram;
            });
          })
        );
        break;
      case 'game-projects':
        this.setColumnDefs(this.allColumnDefs.gameProjectColumnDefs);
        this.rowData$ = this.observableData$.pipe(
          map((gameProjects) => {
            return gameProjects.map((gameProject) => {
              gameProject.modified =
                gameProject.modified instanceof Date
                  ? gameProject.modified
                  : gameProject.modified.toDate();
              gameProject.type =
                gameProject.type.charAt(0).toUpperCase() +
                gameProject.type.substr(1).toLowerCase();
              gameProject.language =
                gameProject.language.charAt(0).toUpperCase() +
                gameProject.language.substr(1).toLowerCase();
              switch (gameProject.type) {
                case 'Tictactoe':
                  gameProject.type = 'Tic-Tac-Toe';
                  break;
                case 'Battleship':
                  gameProject.type = 'Hide and Sink';
                  break;
              }
              return gameProject;
            });
          })
        );
        break;
      case 'competitions':
        this.setColumnDefs(this.allColumnDefs.competitionColumnDefs);
        this.rowData$ = this.observableData$.pipe(
          map((competitions) => {
            return competitions.map((competition) => {
              const formattedCompetition = competition;
              if (competition.sponsors) {
                formattedCompetition.sponsors = competition.sponsors
                  .map((sponsor) => {
                    return `${
                      sponsor.organization ? sponsor.organization + ' - ' : ''
                    }${sponsor.name}`;
                  })
                  .join(', ');
              }
              if (competition.competitionDate) {
                formattedCompetition.competitionDate =
                  competition.competitionDate.toDate();
              }
              return formattedCompetition;
            });
          })
        );
        break;
      case 'tournaments':
        this.setColumnDefs(this.allColumnDefs.tournamentColumnDefs);
        this.rowData$ = this.observableData$.pipe(
          map((tournaments) => {
            return tournaments.map((tournament) => {
              const formattedTournament = tournament as CcpcTournament;
              formattedTournament.entities = tournament?.entities
                ?.map((coach) => {
                  return `${
                    coach.organization ? coach.organization + ' - ' : ''
                  }${coach.name}`;
                })
                .join(', ');
              return formattedTournament;
            });
          })
        );
        break;
      case 'submissions':
        this.setColumnDefs(this.allColumnDefs.submissionColumnDefs);
        this.rowData$ = this.observableData$.pipe(
          map((submissions) => {
            return submissions.map((submission) => {
              const formattedSubmission = submission;
              formattedSubmission.coach = `${
                formattedSubmission.coach.organization
                  ? formattedSubmission.coach.organization + ' - '
                  : ''
              }${formattedSubmission.coach.name}`;

              formattedSubmission.studentName = `${
                formattedSubmission.teammateName
                  ? formattedSubmission.studentName +
                    ' & ' +
                    formattedSubmission.teammateName
                  : formattedSubmission.studentName
              }`;

              formattedSubmission.submitted =
                formattedSubmission.submitted instanceof Date
                  ? formattedSubmission.submitted
                  : formattedSubmission.submitted.toDate();

              return formattedSubmission;
            });
          })
        );
        break;
      case 'coaches':
        this.rowData$ = this.observableData$;
        this.setColumnDefs(this.allColumnDefs.coachColumnDefs);
        break;
      case 'sponsors':
        this.rowData$ = this.observableData$;
        this.setColumnDefs(this.allColumnDefs.sponsorColumnDefs);
        break;
      case 'users':
        this.setColumnDefs(this.allColumnDefs.userColumnDefs);
        this.rowData$ = this.observableData$.pipe(
          map((users) => {
            return users.map((user) => {
              const formattedUser: any = user as any;

              const roles = [
                'admin',
                'editor',
                'student',
                'educator',
                'parent',
                'instructor',
                'partner',
                'customer',
              ];

              const userRoles = roles.map((role) => {
                if (user.roles[role] === true) {
                  return role;
                }
              });

              formattedUser.roles = userRoles.filter((r) => r);

              if (
                formattedUser.created &&
                formattedUser.created instanceof firebase.firestore.Timestamp
              ) {
                formattedUser.created = formattedUser.created.toDate();
              }

              return formattedUser;
            });
          })
        );
        break;
      case 'k12Students':
        this.setColumnDefs(this.allColumnDefs.k12StudentColumnDefs);
        this.rowData$ = this.observableData$.pipe(
          map((k12students) => {
            return k12students.map((k12student) => {
              const formattedUser: any = k12student as any;
              if (
                formattedUser.created &&
                formattedUser.created instanceof firebase.firestore.Timestamp
              ) {
                formattedUser.created = formattedUser.created.toDate();
              }

              return formattedUser;
            });
          })
        );
        break;
      default:
        break;
    }
  }

  onFirstDataRendered({ api: gridApi }: FirstDataRenderedEvent) {
    const clientWidth =
      this.agGridElementRef.nativeElement.querySelector(
        '.ag-body-viewport'
      ).clientWidth;
    // Only if grid is on screen (non-zero width)
    if (clientWidth && clientWidth > 0) {
      gridApi.sizeColumnsToFit();
      this.gridWidth = clientWidth;
    }
  }

  onGridSizeChanged({ api: gridApi, clientWidth }: GridSizeChangedEvent) {
    // Only if width changes and grid is on screen (non-zero width)
    if (this.gridWidth !== clientWidth && clientWidth > 0) {
      switch (this.path) {
        case 'programs':
          this.setColumnDefs(
            this.isAdminView
              ? this.allColumnDefs.adminProgramColumnDefs
              : this.allColumnDefs.programColumnDefs
          );
          break;
        case 'game-projects':
          this.setColumnDefs(this.allColumnDefs.gameProjectColumnDefs);
          break;
        case 'competitions':
          this.setColumnDefs(this.allColumnDefs.competitionColumnDefs);
          break;
        case 'tournaments':
          this.setColumnDefs(this.allColumnDefs.tournamentColumnDefs);
          break;
        case 'submissions':
          this.setColumnDefs(this.allColumnDefs.submissionColumnDefs);
          break;
        case 'coaches':
          this.setColumnDefs(this.allColumnDefs.coachColumnDefs);
          break;
        case 'sponsors':
          this.setColumnDefs(this.allColumnDefs.sponsorColumnDefs);
          break;
        case 'users':
          this.setColumnDefs(this.allColumnDefs.userColumnDefs);
          break;
        case 'k12Students':
          this.setColumnDefs(this.allColumnDefs.k12StudentColumnDefs);
          break;
        default:
          break;
      }
      gridApi.sizeColumnsToFit();
      this.gridWidth = clientWidth;
    }
  }

  setColumnDefs(columns) {
    if (this.agGrid) {
      this.agGrid.api.setColumnDefs([]);
      if (window.innerWidth < 767.98) {
        this.agGrid.api.setColumnDefs(columns.mobile);
      } else if (window.innerWidth > 767.99 && window.innerWidth < 992) {
        this.agGrid.api.setColumnDefs(columns.medium);
      } else if (window.innerWidth > 992 && window.innerWidth < 1281) {
        this.agGrid.api.setColumnDefs(columns.large);
      } else if (window.innerWidth > 1281) {
        this.agGrid.api.setColumnDefs(columns.xl);
      }
    } else {
      if (window.innerWidth < 767.98) {
        this.columnDefs = columns.mobile;
      } else if (window.innerWidth > 767.99 && window.innerWidth < 992) {
        this.columnDefs = columns.medium;
      } else if (window.innerWidth > 992 && window.innerWidth < 1281) {
        this.columnDefs = columns.large;
      } else if (window.innerWidth > 1281) {
        this.columnDefs = columns.xl;
      }
    }
  }

  timeValueFormatter(params) {
    if (params.value) {
      return params.value.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
    } else {
      return '';
    }
  }

  dateValueFormatter(params) {
    if (params.value) {
      return params.value.toLocaleDateString();
    } else {
      return '';
    }
  }

  fullTimeDateFormatter(params) {
    if (params.value) {
      return (
        params.value.toLocaleDateString() +
        ' ' +
        params.value.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      );
    } else {
      return '';
    }
  }

  priceValueFormatter(params) {
    if (params.value) {
      return '$' + params.value / 100;
    } else {
      return '';
    }
  }
}
