<div
  class="matchup d-flex flex-column justify-content-center align-items-center shadow"
  (click)="openModal(gameModal)"
>
  <span
    class="p-1"
    [ngClass]="{ winner: match.winner === match.players[0] && showWinner }"
  >
    {{ match.players[0] }} {{ emoji(match.players[0]) }}
  </span>
  <span
    *ngIf="match.players.length > 1"
    class="p-1"
    [ngClass]="{ winner: match.winner === match.players[1] && showWinner }"
  >
    {{ match.players[1] }} {{ emoji(match.players[1]) }}
  </span>
</div>

<ng-template #gameModal>
  <div class="modal-header">
    <ng-container
      *ngIf="includeLinksToPlayers && match.playerIds; else noLinksToPlayers"
    >
      <p *ngIf="this.gameType === 'tictactoe'" class="h3 modal-title mx-auto">
        <a [routerLink]="['/battlescripts/arena/', match.playerIds[0]]">{{
          match.game.players.player1.name
        }}</a>
        ({{ match.game.players.player1.team }}) vs
        <a [routerLink]="['/battlescripts/arena/', match.playerIds[1]]">{{
          match.game.players.player2.name
        }}</a>
        ({{ match.game.players.player2.team }})
      </p>
      <p *ngIf="this.gameType === 'battleship'" class="h3 modal-title mx-auto">
        <a [routerLink]="['/battlescripts/arena/', match.playerIds[0]]">{{
          match.players[0]
        }}</a>
        vs
        <a [routerLink]="['/battlescripts/arena/', match.playerIds[1]]">{{
          match.players[1]
        }}</a>
      </p>
    </ng-container>
    <ng-template #noLinksToPlayers>
      <p *ngIf="this.gameType === 'tictactoe'" class="h3 modal-title mx-auto">
        {{ match.game.players.player1.name }} ({{
          match.game.players.player1.team
        }}) vs {{ match.game.players.player2.name }} ({{
          match.game.players.player2.team
        }})
      </p>
      <p *ngIf="this.gameType === 'battleship'" class="h3 modal-title mx-auto">
        {{ match.players[0] }} vs {{ match.players[1] }}
      </p>
    </ng-template>
  </div>
  <div class="modal-body">
    <app-game-display
      *ngIf="match && language && gameType"
      [gameResults]="match"
      [language]="language"
      [gameType]="gameType"
      [tournamentRun]="true"
      [showWinner]="showWinner"
      [includeLinkToWinner]="includeLinksToPlayers"
    >
    </app-game-display>
  </div>
  <div class="modal-footer">
    <p *ngIf="match?.game?.winningTimeDifference && showWinner">
      Compute Time Difference:
      {{ match.game.winningTimeDifference }} nanoseconds
    </p>
    <button
      *ngIf="!showWinner"
      type="button"
      class="btn btn-primary"
      (click)="showWinner = true"
    >
      Show Winner
    </button>
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">
      Close
    </button>
  </div>
</ng-template>
